<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="6">
        <app-text-field class="mr-4" label="Data" solo flat background-color="grey lighten-4"
          v-model="params.start_date" type="date" />
        <app-text-field class="mr-4" label="Data" solo flat background-color="grey lighten-4" v-model="params.end_date"
          type="date" />
        <v-btn class="mr-4" color="primary" @click="select()">Pesquisar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex" cols="6">
        <CompanySelect class="mr-4" :item-value="(item) => item.id" v-model="params.company_id" clearable solo flat
          background-color="grey lighten-4" :dense="false" @change="select()" />

        <SalesmanSearch background-color="grey lighten-4" solo flat :dense="false" label="Vendedor" hidePosition="true"
          @input="setSalesmanId($event)" clearable />
      </v-col>
    </v-row>
    <v-row class="mt-10 justify-space-between">
      <v-col>
        <v-card color="primary" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total em vendas bruto
              <br />
              <b class="text-h4">{{ $format.decimal(saleReport.net_total_sum) }}</b>
              <br />
            </div>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total em vendas c/desconto
              <br />
              <b class="text-h4">{{ $format.decimal(saleReport.net_price_cash_total_sum) }}</b>
              <br />
              % de desconto {{ $format.decimal(calculateDiscountPercentage()) }}%
            </div>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total de devolução de vendas c/desconto
              <br />
              <b class="text-h4">{{ $format.decimal(returnReport.net_price_cash_total_sum) }}</b>
              <br />
              desconto bruto {{ $format.decimal(returnReport.net_total_sum) }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <apexchart width="100%" height="300px" type="bar" :options="chartOptions" :series="chartSeries">
          </apexchart>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text>
            Vendas por meio de pagamento
            <template v-for="row in salePaymentReport.data">
              <div class="my-4">
                <div class="d-flex justify-space-between align-center">
                  <div>
                    {{ row.payment_name }}
                  </div>
                  <div class="text-right">
                    <h3 class="text-primary font-weight-regular">
                      R$ {{ $format.decimal(row.sum_value) }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>
            <app-pagination :data="salePaymentReport" @click="getSalePaymentReport($event)" />
          </v-card-text>
        </v-card>

        <v-card color="grey lighten-4" elevation="0" class="mt-6">
          <v-card-text>
            Vendas por marca
            <template v-for="row in saleByBrandReport.data">
              <div class="my-4">
                <div class="d-flex justify-space-between align-center mb-1">
                  <div>
                    {{ row.brand_name }}
                  </div>
                  <div class="text-right">
                    <h3 class="mt-3 text-primary font-weight-regular">
                      R$ {{ $format.decimal(row.net_price_cash_total_sum) }}
                    </h3>
                    <small>Lucro: </small>
                    <small>R$ {{ $format.decimal(row.profit_sum) }}</small>
                  </div>
                </div>
                <!-- <div>
                  <v-progress-linear height="8px" class="rounded-xl" color="primary"></v-progress-linear>
                </div> -->
              </div>
            </template>
            <app-pagination :data="saleByBrandReport" @click="getSalesByBrand($event)" />
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="6">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text>
            Vendas por categoria de produtos
            <template v-for="row in saleProductByCategoryReport.data">
              <div class="my-4">
                <div class="d-flex justify-space-between align-center mb-1">
                  <div>
                    {{ row.category_name }}
                    <small class="text-medium-emphasis">
                      <!-- ({{ $format.decimal()}}%) -->
                    </small>
                  </div>
                  <div class="text-right">
                    <h3 class="mt-3 text-primary font-weight-regular">
                      R$ {{ $format.decimal(row.net_price_cash_total_sum) }}
                    </h3>
                    <small>Lucro: </small>
                    <small>R$ {{ $format.decimal(row.profit_sum) }}</small>
                  </div>
                </div>
                <!-- <div>
                  <v-progress-linear height="8px" class="rounded-xl" color="primary"></v-progress-linear>
                </div> -->
              </div>
            </template>
            <app-pagination :data="saleProductByCategoryReport" @click="getSaleProductByCategoryReport($event)" />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="6">

        <v-card color="grey lighten-4" elevation="0">
          <v-card-text>
            Situação da entrega
            <div class="my-4">
              <div class="d-flex justify-space-between align-center">
                <div>
                  {{ saleReport }}
                  {{ row.payment_name }}
                </div>
                <div class="text-right">
                  <h3 class="text-primary font-weight-regular">
                    R$ {{ $format.decimal(row.sum_value) }}
                  </h3>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>

      </v-col> -->
    </v-row>

  </div>
</template>

<script>
import { format } from "date-fns";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch.vue";

export default {
  components: {
    CompanySelect,
    SalesmanSearch,
  },
  data: () => ({
    params: {
      page: 1,
      company_id: null,
      salesman_id: null,
      start_date: format(new Date(), "yyyy-MM-01"),
      end_date: format(new Date(), "yyyy-MM-dd"),
    },

    saleProductParams: {
      metrics: ["net_total_sum", 'profit_sum'],
      dimensions: ["product.brand.name"],
    },

    saleByBrandReport: {},
    saleReport: {},
    returnReport: {},
    saleProductResult: [],
    salePaymentReport: {},
    saleProductByCategoryReport: {},
    saleDeliveryStatusReport: {},
    saleByMonthChart: {},

    chartSeries: [],

    chartOptions: {
      chart: {
        id: 'vuechart-example',
        type: 'bar',

        height: 400,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },

      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },

      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
          dataLabels: {

            total: {
              enabled: false,
              style: {
                fontSize: '12px',
                fontWeight: 500
              }
            }
          },
          columnWidth: '70%',
        },
      },

      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `R$ ${val.toFixed(2)}`;
        },
        offsetY: -20,
        position: 'center',
        style: {
          align: 'center',
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: [],
        labels: {
          position: 'top',
          style: {
            align: 'center',
            colors: '#9aa0ac',
            fontSize: '12px',
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return `R$ ${val.toFixed(2)}`;
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {

        y: {
          formatter: function (val) {
            return `R$ ${val.toFixed(2)}`;
          },
        },
      },
      colors: ['#1E90FF', '#32CD32'],
    },
  }),

  created() {
    this.select();
  },

  methods: {
    select() {

      this.getSalesReport();

      this.getSalePaymentReport();

      //this.getSalesProduct();

      this.getSalesByBrand();

      this.getSaleProductByCategoryReport();

      this.getSaleByMonthChart();
    },

    calculateDiscountPercentage() {
      if (this.saleReport) {
        const totalSum = parseFloat(this.saleReport.net_total_sum || 0);
        const totalDiscount = parseFloat(this.saleReport.discount_sum || 0);
        const percentage = (totalDiscount / totalSum) * 100;
        return totalSum > 0 ? percentage : 0;
      }
      return 0;
    },

    getSalesProduct() {
      this.$http.index("bi/sale-product", this.saleProductParams).then((response) => {
        this.saleProductResult = response.saleProduct.sort((a, b) => {
          return b.profit_sum - a.profit_sum;
        });
      });
    },

    getSalesReport() {
      this.$http.index('sale/sale-report', this.params).then((response) => {
        this.returnReport = response.returnReport;
        this.saleReport = response.saleReport;
      });
    },

    getSalePaymentReport(page = 1) {
      this.$http.index('sale/sale-payment-report', { ...this.params, page: page }).then((response) => {
        this.salePaymentReport = response.salePaymentReport;
      });
    },

    getSalesByBrand(page = 1) {
      this.$http.index('sale/sale-by-brand-report', { ...this.params, page: page }).then((response) => {
        this.saleByBrandReport = response.sales;
      });
    },

    getSaleProductByCategoryReport(page = 1) {
      this.$http.index('sale/sale-product-by-category-report', { ...this.params, page: page }).then((response) => {
        this.saleProductByCategoryReport = response.saleProductByCategoryReport;
      });
    },

    getSaleDeliveryReport() {
      this.$http.index('sale/sale-delivery-status-report', this.params).then((response) => {
        this.saleDeliveryStatusReport = response.saleDeliveryStatusReport;
      });
    },

    getSaleByMonthChart() {
      this.$http.index('sale/sale-by-month-chart', this.params).then((response) => {
        this.saleByMonthChart = response.saleByMonthChart;
        this.buildMonthlyChart();
      });
    },

    setSalesmanId(value) {

      this.params.salesman_id = value ? value.id : null
      this.select();
    },

    buildMonthlyChart() {
      this.chartOptions.xaxis.categories = this.saleByMonthChart.map(item => item.month);
      this.chartSeries = [{
        name: 'Total Bruto',
        data: this.saleByMonthChart.map(item => parseFloat(item.sum_total))
      }, {
        name: 'Total Líquido',
        data: this.saleByMonthChart.map(item => parseFloat(item.total))
      }];
    },


  },
};
</script>

<style></style>
